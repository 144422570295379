import { LandingContext } from 'common/contexts/Landing/Landing'
import { useResolution } from 'common/hooks'
import { Footer } from 'modules/Footer'
import { Header } from 'modules/Header'
import { Layout } from 'modules/layouts'
import { ModalAppLoad } from 'modules/modals/MobAppLoad'
import { useContext, useEffect } from 'react'

export const Landing = () => {
  const { landing } = useContext(LandingContext)
  const { isMinScreen768 } = useResolution()
  const metaDescription = document.querySelector(
    'meta[name="description"]',
  ) as HTMLMetaElement
  const metaKeyWords = document.querySelector(
    'meta[name="keywords"]',
  ) as HTMLMetaElement

  useEffect(() => {
    const metasKeys = landing.metaKeywords
    metaDescription.setAttribute('content', `${landing.metaDescription}`)
    if (metasKeys) {
      metaKeyWords.setAttribute('content', metasKeys.join())
    }
  }, [landing])

  return (
    <>
      {!isMinScreen768 && <ModalAppLoad />}
      <Header />
      <Layout.Main />
      <Layout.BookTable />
      <Layout.AboutUs />
      <Layout.Decoration />
      <Layout.OtherRestaurants />
      <Layout.Contacts />
      <Footer />
    </>
  )
}

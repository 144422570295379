import React from 'react'
import { useField } from 'formik'
import { Standart } from './Standart'
import { TInputProps } from './types'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'

type InputFieldProps = Omit<TInputProps, 'isInvalid' | 'errorText'>

type TInputMaskCorrect = Omit<InputMaskProps, 'children'> &
  Omit<TInputProps, 'isInvalid' | 'errorText'> & { name?: string } & {
    children?: (inputProps: TInputProps) => JSX.Element
  }

export const InputField: React.FC<InputFieldProps> = ({ name, ...rest }) => {
  const [field, meta] = useField(name)
  return (
    <Standart
      {...field}
      {...rest}
      isInvalid={Boolean(meta.error && meta.touched)}
      errorText={String(meta.error)}
    />
  )
}

export const CustomInputField: React.FC<TInputMaskCorrect> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name)
  return (
    <InputMask {...field} {...props}>
      <Standart
        name={name}
        {...props}
        isInvalid={Boolean(meta.error && meta.touched)}
        errorText={String(meta.error)}
      />
    </InputMask>
  )
}
